import React, { useEffect } from 'react';
import './Recognition.css';
import { Image } from 'react-bootstrap';
const images = [...Array(13)].map((_, index) => require(`../Images/Recognition/${index + 1}.png`));

const recognitionsArray = [
    { image: images[0], title: "Winner", url: '' },
    { image: images[1], title: "Community Action Grant Recipient", url: '' },
    { image: images[2], title: "Winner", url: '' },
    { image: images[3], title: "Winner", url: '' },
    { image: images[4], title: "SAAS Winner", url: '' },
    { image: images[5], title: "Winner", url: '' },
    { image: images[6], title: "Semi-Finalist (Ongoing)", url: '' },
    { image: images[7], title: "LA Winner", url: '' },
    { image: images[8], title: "AZ Inno Under 25", url: 'https://www.bizjournals.com/phoenix/inno/stories/news/2023/09/15/az-inno-25-under-25.html' },
    { image: images[9], title: "Grant recipient", url: '' },
    { image: images[10], title: "Featured", url: 'https://www.statepress.com/article/2023/02/open-pitch-week-entrepreneurship#' },
    { image: images[11], title: "Featured", url: 'https://fullcircle.asu.edu/features/student-entrepreneurs-pitch-innovative-ideas/' },
    { image: images[12], title: "Featured", url: 'https://www.wsj.com/business/entrepreneurship/startup-founders-fret-over-getting-fired-like-sam-altman-1c91917c?st=py1v0zfljerm6gr&reflink=article_imessage_share' },
  ];

const Recognition: React.FC = () => {



    useEffect(() => {
        document.title = 'Recognition | BreatheEV';
    }, []);


    const cards = recognitionsArray.map((card, index) => (
        <div key={'cards-' + index} className="col-sm-12 col-md-6 py-3">
          <div className='recognition-clm'>
            {card.url ? (
              <a href={card.url} target="_blank" rel="noopener noreferrer">
                <h2 className="card-text">{card.title}</h2>
                <Image src={card.image} />
              </a>
            ) : (
              <>
                <h2 className="card-text">{card.title}</h2>
                <Image src={card.image} />
              </>
            )}
          </div>
        </div>
      ));
      



    return (
        <main id="main">
            <section id="recognition-main">
                <div className="container text-center">
                    <h1>OUR Recognition</h1>
                </div>
            </section>

            <section id="recognition-row" className="container recognition-row  mt-3 bg-light mb-5">
                <div className="container-fluid">

                    <div className="row">
                        {cards}
                    </div>

                </div>
            </section>

        </main>
    );
};

export default Recognition;
